var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('product.init inv_status_data:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);
    var productId = $statusList.data('product-id');
    var productData = prodcat.data.getProduct(productId);
    var productSkusCount = productData?.skus?.length ?? null;
    var skusSoldout = [];
    var skusTempOutOfStock = [];
    var skusComingSoon = [];
    var productRoute = $(this).data('product-route');
    var isYmal = $(this).data('is-ymal');

    if (productData !== null) {
      $(productData.skus).each(function (index, sku) {
        if (sku.INVENTORY_STATUS === 7) {
          skusSoldout.push(sku);
        } else if (sku.INVENTORY_STATUS === 2) {
          skusTempOutOfStock.push(sku);
        } else if (sku.INVENTORY_STATUS === 3) {
          skusComingSoon.push(sku);
        }
      });
    }

    if (skuData && (skusSoldout.length > 0
      || skusTempOutOfStock.length > 0
      || skusComingSoon.length > 0)) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    } else if (skuData && productRoute && isYmal !== true) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $statusList = $('.js-inv-status-list', $(this));

    if ($statusList.length < 1) {
      return null;
    }
    Drupal.behaviors.inventoryStatusV1.selectSku($statusList, skuBaseId);
  });

  Drupal.behaviors.inventoryStatusV1 = {
    update: function ($statusList, skuData) {
      var skuBaseId = $statusList.data('sku-base-id');

      // Hide the Inventory status messages
      $('li', $statusList).hide();
      if (!skuData || !skuData.INVENTORY_STATUS || skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }
      var $statusToShow = $('.js-inv-status-' + skuData.INVENTORY_STATUS, $statusList);

      if ($statusToShow.length > 0) {
        $statusToShow.show();
        $statusList.trigger('inv_status_display:updated');
      }
    },

    selectSku: function ($statusList, skuBaseId) {
      $statusList.data('sku-base-id', skuBaseId);
      var skuData = prodcat.data.getSku(skuBaseId);

      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  };
})(jQuery);
